import React from "react";

const ContentWrapper: React.FC = ({ children }) => {
  return (
    <section className="c-viewport uk-section">
      <div className="uk-container">
        <div className="uk-width-1-1 uk-width-3-4@m uk-width-2-3@l uk-margin-auto c-viewport__green-headers">
          {children}
        </div>
      </div>
    </section>
  );
};

export default ContentWrapper;
